import * as React from 'react';
import './styles.scss';
import { useSearchParams } from 'client/shared/hooks';

export const BalancingActSimulationIFrame: React.FC<{ readonly url: string }> = (
  p
) => {
  // BA inserts and then checks for this to allow viewing inactive simulations and not affect analytics
  const preview = useSearchParams().preview();

  return (
    <iframe
      className="pn-ba-simulation-iframe"
      src={p.url + (preview ? `?preview=${preview}` : '')}
      title="balancingActSim"
    />
  );
};
